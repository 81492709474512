import React, { useState } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import useSWR from "swr";
import moment from "moment-timezone";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";
import { AiOutlineLoading, AiOutlineChrome } from "react-icons/ai";
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import useAuth from "@components/hooks/useAuth";
import axios from "axios";
import { toast } from "react-toastify";

const endpoints = {
    convocatorias: "/.netlify/functions/convocatorias",
};

const fetcher = async (url, token) => {
    try {
        const config = token ? { headers: { Authorization: "Bearer " + token } } : {};
        const response = await axios.post(url, null, config);
        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        throw new Error("Error al cargar datos");
    }
};

const Component = () => {
    const { state } = useAuth();

    // Clave para useSWR
    const key = state?.jwt ? [endpoints.convocatorias, state.jwt] : [endpoints.convocatorias];

    // Obtener datos y estado de carga desde useSWR
    const { data, isLoading } = useSWR(key, fetcher);

    const [filter, setFilter] = useState("");

    // Filtramos convocatorias, con tildes y sin tildes
    let filteredData =
        filter !== ""
            ? data?.data.filter(
                  i =>
                      i.institucioDesenvolupat
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/\p{Diacritic}/gu, "")
                          .includes(filter.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ||
                      i.titol
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/\p{Diacritic}/gu, "")
                          .includes(filter.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ||
                      i.dataPublicacio?.toLowerCase().includes(filter) ||
                      i.dataFinalitzacio?.toLowerCase().includes(filter) ||
                      i.estat?.toLowerCase().includes(filter),
              )
            : [];

    return (
        <>
            <Seo
                title="Convocatorias de Oposiciones disponibles"
                description="Consulta todas las plazas de policía local."
            />
            <Layout>
                <SmallBanner
                    title="Convocatorias de Oposiciones disponibles"
                    subtitle="Consulta todas las plazas de policía local"
                    alt="Convocatorias de Oposiciones disponibles"
                    filename="bgs/bgCido"
                />

                <div className="container py-10">
                    <Title>Convocatorias de Oposiciones disponibles</Title>
                    <Text className="text-justify">
                        A continuación te ofrecemos una lista con las convocatorias de plazas
                        convocadas y toda la información al respecto.
                    </Text>

                    {isLoading && (
                        <div className="mt-5">
                            <AiOutlineLoading className="text-center w-5 h-5 animate-spin duration-300" />
                        </div>
                    )}

                    {data && (
                        <div className="mt-10 font-montserrat">
                            {/** Filtro y número de resultados */}
                            <div className="flex items-center justify-between text-gray-600">
                                <div>
                                    Se han encontrado{" "}
                                    <strong className="text-ccyan font-semibold">
                                        {filter === ""
                                            ? data?.data?.length
                                            : filteredData.length || 0}
                                    </strong>{" "}
                                    resultado/s.
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        placeholder="Filtrar por texto"
                                        className="rounded border border-gray-300 px-3 py-1 text-sm text-gray-700"
                                        onChange={e =>
                                            setFilter(e.target.value.toLocaleLowerCase())
                                        }
                                        value={filter}
                                    />
                                </div>
                            </div>

                            {/* Mostramos resultados*/}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
                                {(filter === "" ? data?.data : filteredData).map((item, idx) => {
                                    return (
                                        <Item
                                            key={`${item.id}-${idx}`}
                                            data={item}
                                            idx={idx}
                                            favorite={data?.favorites.indexOf(item.id) !== -1}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {/** Disclaimer */}
                    <div className="font-montserrat text-xs text-center md:text-right text-gray-500 mt-10 md:w-1/2 md:ml-auto">
                        * Los resultados que se muestran en esta página están extraídos de la de{" "}
                        <a
                            href="https://dadesobertes.diba.cat/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="border-b hover:border-transparent duration-300 text-gray-600"
                        >
                            Dades Obertes de la DIBA
                        </a>
                        . iOpos no se ha responsable de la exactitud de estas.
                    </div>
                </div>
            </Layout>
        </>
    );
};

const Item = ({ data, idx, favorite: fav }) => {
    const { state, isAuthenticated } = useAuth();
    const [favorite, setFavorite] = useState(fav || false);
    const [loading, setLoading] = useState(false);

    // En caso que haya un cambio
    // respecto el valor inicial pues enviamos al server la modificación
    const handleState = async () => {
        setLoading(true);

        const id = toast.loading("Guardando...", {
            position: "bottom-center",
        });

        try {
            const url = `/.netlify/functions/convocatorias-change-favorite`;
            const token = state?.jwt;

            // Hacemos el cambio
            const json = await axios.post(
                url,
                {
                    id: data.id,
                    value: !favorite,
                },
                {
                    ...(token && {
                        headers: { Authorization: "Bearer " + token },
                    }),
                },
            );

            if (json?.data?.success) {
                setFavorite(json?.data?.value);
                toast.update(id, {
                    render: json?.data?.value
                        ? "Convocatoria guardada correctamente."
                        : "Convocatoria eliminada correctamente.",
                    type: "success",
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 3000,
                });
            } else {
                toast.update(id, {
                    render: "La convocatoria no se ha podido procesar.",
                    type: "error",
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                });
            }
        } catch (e) {
            console.log(e);

            toast.update(id, {
                render: "La convocatoria no se ha podido procesar.",
                type: "error",
                isLoading: false,
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });
        }

        setLoading(false);
    };

    return (
        <div
            style={{ minHeight: "275px" }}
            className={`rounded shadow-md overflow-hidden relative ${
                idx % 2 === 0 ? "bg-gray-50 bg-opacity-50" : "bg-white"
            }`}
        >
            <div className="bg-ccyan-dark text-white px-5 py-2 pr-2 flex justify-between space-x-5">
                <h1
                    title={data?.institucioDesenvolupat}
                    className="font-montserrat uppercase truncate-2-lines text-white"
                >
                    {data?.institucioDesenvolupat}
                </h1>
                <div
                    className="text-xs text-gray-300 pt-1"
                    style={{ fontSize: "10px" }}
                    title="Data publicació"
                >
                    {moment(data?.dataPublicacio).tz("Europe/Madrid").format("DD/MM/YYYY")}
                </div>
            </div>

            <div className="m-5 space-y-2">
                <h2
                    title={data?.titol}
                    className="uppercase font-semibold text-sm font-montserrat text-gray-500 truncate-3-lines"
                >
                    {data?.titol}
                </h2>

                <div className="space-x-1  flex items-center">
                    {data?.idEstat === 3 && <MdCheckBox className="w-4 h-4 text-green-700" />}
                    {data?.idEstat === 2 && <MdCheckBox className="w-4 h-4 text-yellow-700" />}
                    <span className="font-montserrat text-sm">{data?.estat}</span>
                </div>

                {data?.idEstat === 3 && (
                    <div className="text-sm font-montserrat flex items-center space-x-2">
                        <span className="font-semibold text-gray-500">Fecha finalización:</span>
                        <span className="text-gray-800">
                            {data?.dataFinalitzacio ? (
                                moment(data?.dataFinalitzacio)
                                    .tz("Europe/Madrid")
                                    .format("DD/MM/YYYY")
                            ) : (
                                <>-</>
                            )}
                        </span>
                    </div>
                )}

                <div className="absolute mt-10 bottom-0 left-0 w-full">
                    <div className="items-center justify-between flex mx-3 mb-3">
                        <div className="items-center flex space-x-2">
                            <Link
                                to={`/convocatoria/${data?.id}`}
                                title="Más información sobre la convocatoria"
                                className="duration-300 rounded bg-ccyan hover:bg-ccyan-dark text-white px-5 py-2"
                            >
                                <AiOutlineChrome className="w-4 h-4" />
                            </Link>
                        </div>

                        {isAuthenticated && (
                            <button
                                disabled={loading}
                                className={`duration-300 ${
                                    loading ? "opacity-50 animate-pulse cursor-wait" : "opacity-100"
                                }`}
                                title="Marcar/Desmarcar como favorito"
                                onClick={() => handleState()}
                            >
                                {favorite ? (
                                    <MdOutlineFavorite className="w-6 h-6 text-red-700" />
                                ) : (
                                    <MdOutlineFavoriteBorder className="w-6 h-6 text-red-700" />
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Component;
